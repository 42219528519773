<template>
  <div>
    <!-- debug -->
    <div v-if="false">
      <b-button-group size="sm" class="cbs-inline-spacing">
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="isDebug ? 'primary' : 'outline-primary'"
                  @click="isDebug = !isDebug"
        >
          <feather-icon icon="CodeIcon" />
          {{ $options.name }}
        </b-button>
      </b-button-group>
      <div v-if="isDebug" style="margin-left: 10px;margin-right: 10px;">
        Props: <json-viewer :value="getProps()" :expand-depth=0 sort/>
        Data: <json-viewer :value="getData()" :expand-depth=0 sort/>
      </div>
    </div>
    <!-- /gebug -->
    <cbs-debug v-if="!hide" :context="this" />
  </div>
</template>

<script>
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import JsonViewer from 'vue-json-viewer'
import {
  BButton, BButtonGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'

export default {
  name: 'CbsDataQuery',
  directives: {
    Ripple,
  },
  components: {
    CbsDebug,
    JsonViewer,
    BButtonGroup,
    BButton,
  },
  props: {
    object: {
      type: Object,
      default: null,
    },
    domainsid: {
      type: String,
      default: null,
    },
    filter: {
      type: Object,
      default: null,
    },
    groupby: {
      type: Object,
      default: null,
    },
    orderby: {
      type: Object,
      default: null,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    offset: {
      type: Number,
      default: 0,
    },
    hide: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'load',
  ],
  data() {
    return {
      query: null,
      isDebug: false,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.loadQuery()
    },
    loadQuery() {
      console.log('loadQuery')
      console.log('object', this.object)
      console.log('filter', this.filter)
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'query',
          param: {
            objectid: this.object.object.id,
            domainsid: this.domainsid,
            filter: this.filter,
            groupby: this.groupby,
            orderby: this.orderby,
            limit: this.pageSize,
            offset: this.offset,
          },
        },
      })
        .then(response => {
          console.log('response', response)
          if (response.data.thread) {
            this.threadQuery(response.data.thread)
          } else if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          }
        })
        .catch(error => {
          console.log('error', error)
        })
    },
    delayQuery(thread) {
      setTimeout(() => this.threadQuery(thread), 500)
    },
    threadQuery(thread) {
      useJwt.query({
        query: {
          method: 'thread',
          param: {
            threadname: thread,
          },
        },
      }).then(response => {
        console.log('response', response)
        if (response.data.thread.status === 'done') {
          this.query = response.data.thread.result.query
          this.$emit('load', this.query)
        } else if (response.data.thread.status === 'error') {
          useCubus.toastError(this, response.data.thread.error)
        } else {
          this.delayQuery(thread)
        }
      }).catch(error => {
        console.log('error', error)
        useCubus.toastError(this, error)
      })
    },
    getData() {
      return this._data
    },
    getProps() {
      return this._props
    },
  },
}
</script>

<style scoped>

</style>
