<template>
  <div>
    <cbs-collapse :trigger="ref != null">
      <div>
        <v-select v-if="ref"
          v-model="record[field.key]"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="ref"
          @input="onInputVSelect($event)"
          :class="variant"
          :filterBy="filterBy"
        >
          <template slot="option" slot-scope="item">
            {{ item.title }}
            <small class="text-muted"><i>({{ item.value }})</i></small>
          </template>
        </v-select>
        <div>
          <span>
            <feather-icon icon="RotateCwIcon" class="cursor-pointer text-primary" @click="refreshRef()"></feather-icon>
            <a style="margin-left:5px;" :href="'/cubus/object/' + field.refobjectid" target="_blank"><feather-icon icon="ExternalLinkIcon" class="cursor-pointer text-primary" /></a>
          </span>
        </div>
      </div>
    </cbs-collapse>
    <cbs-data-reference :ref="'ref_' + uuid" hide :entityid="refEntityId()" @load="onLoadRef" />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import CbsDataReference from '@/cubus/components/reference/CbsDataReference.vue'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'CbsReferencePicker',
  components: {
    CbsCollapse,
    vSelect,
    CbsDataReference,
  },
  props: {
    field: {
      type: Object,
      default: null,
    },
    variant: {
      type: String,
      default: '',
    },
    record: {
      type: Object,
      default: null,
    },
  },
  emits: ['updateCell'],
  data() {
    return {
      ref: null,
      uuid: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.uuid = useCubus.guid()
    },
    onLoadRef(ref) {
      this.ref = ref
    },
    filterBy(option, label, search) {
      const pattern = `^${search
        .replace(new RegExp(' ', 'gi'), '.*')
        .replace(new RegExp('\\.\\*\\.\\*', 'gi'), ' ')}.*`
      const re = new RegExp(pattern, 'gi')
      return re.test(label || '')
    },
    onInputVSelect(val) {
      console.log('onInputVSelect', val)
      if (!val) {
        this.record[this.field.key] = { value: 0, title: '' }
      }
      this.$emit('updateCell', !val ? { value: 0, title: '' } : val)
    },
    refEntityId() {
      if (this.field.entitysid === 'parent') {
        return this.field.parententityid
      }
      return this.field.entityid
    },
    loadRef() {
      this.$refs[`ref_${this.uuid}`].loadRef()
    },
    refreshRef() {
      this.$refs[`ref_${this.uuid}`].loadRef()
    },
  },
}
</script>

<style scoped>

</style>
