<template>
  <div>
    <b-row>
      <b-col>
        <b-button-toolbar key-nav aria-label="Toolbar with button groups" class="cbs-inline-spacing">
          <b-button-group size="sm" class="cbs-inline-spacing">
            <b-button size="sm" variant="flat-primary" @click="isExpanded = !isExpanded"
                      :disabled="!orderby.sorts.length">
              <feather-icon :icon="expandIcon()" />
            </b-button>
          </b-button-group>
          <b-button-group size="sm" class="cbs-inline-spacing">
            <b-form-checkbox v-model="orderby.isActive"
                             name="check-button"
                             switch
                             inline
                             class="custom-control-success"
            />
          </b-button-group>
          <b-button-group size="sm" class="cbs-inline-spacing">
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" @click="addSort()">
              <feather-icon icon="PlusIcon" />
              Sort
            </b-button>
          </b-button-group>
        </b-button-toolbar>
        <div v-if="isExpanded" style="margin-left:20px;margin-top:15px;">
          <div v-for="(sort, index) in orderby.sorts" :key="index">
            <cbs-orderby-item
              :sort="sort"
              :index="index"
              :fields="fields"
              :opers="opers"
              @delete="onSortDelete(index)"
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, BButtonGroup, BButtonToolbar, BCol, BFormCheckbox, BRow,
} from 'bootstrap-vue'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import CbsOrderbyItem from '@/cubus/components/orderby/CbsOrderbyItem.vue'

export default {
  name: 'CbsOrderby',
  directives: {
    Ripple,
  },
  components: {
    CbsOrderbyItem,
    BRow,
    BCol,
    BButtonGroup,
    BFormCheckbox,
    BButton,
    BButtonToolbar,
  },
  props: {
    orderby: {
      type: Object,
      default: () => ({ isActive: false, sorts: [] }),
    },
    fields: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      isExpanded: true,
      opers: [
        { sid: 'asc', name: 'Acs' },
        { sid: 'desc', name: 'Desc' },
      ],
    }
  },
  created() {
    this.init()
  },
  methods: {
    expandIcon() {
      if (!this.orderby.sorts.length) {
        return 'XIcon'
      }
      if (this.isExpanded) {
        return 'MinusIcon'
      }
      return 'PlusIcon'
    },
    addSort() {
      this.orderby.sorts.push(this.getDefaultSort())
    },
    getDefaultSort() {
      return {
        isActive: false,
        name: 'sort',
        oper: { sid: 'asc', name: 'Asс' },
      }
    },
    onSortDelete(index) {
      this.orderby.sorts.splice(index, 1)
    },
    init() {
      this.loadOpers()
    },
    loadOpers() {
      useJwt.query({
        query: {
          method: 'orderbyoperands',
          param: {},
        },
      })
        .then(response => {
          console.log('orderbyoperands response', response)
          if (response.data.thread) {
            this.delayOpers(response.data.thread)
          } else if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else {
            useCubus.toastError(this, 'Thread not found.')
          }
        })
        .catch(error => {
          console.log('orderbyoperands error', error)
          useCubus.toastError(this.error)
        })
    },
    delayOpers(thread) {
      setTimeout(() => this.threadOpers(thread), 1000)
    },
    threadOpers(thread) {
      useJwt.query({
        query: {
          method: 'thread',
          param: { threadname: thread },
        },
      })
        .then(response => {
          console.log('thread response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread.status === 'done') {
            this.opers = response.data.thread.result.opers
          } else if (response.data.thread.error) {
            useCubus.toastError(this, response.data.thread.error)
          } else {
            this.delayOpers(thread)
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
  },
}
</script>

<style scoped>

</style>
