<template>
  <div>
    <b-row v-if="objectFull">
      <b-col cols="12">
        <cbs-card-actions ref="mainCard"
                          :title="objectFull && object() && objectFull.object.name ? objectFull.object.name + ' (id:' + objectid() + ')' : 'OBJECT ' + objectid()"
                          :sub-title="descr()"
                          @refresh="refreshStop('mainCard')"
                          @close="closeCard()"
        >
          <b-card-body>
            <cbs-object-import v-if="meta() === 'import'"
                               ref="refImport"
                               :object="object()"
                               :object-full="objectFull"
                               @close="closeCard()"
            />
            <cbs-object-procedure v-else-if="meta() === 'procedure'"
                                  ref="refProc"
                                  :object-full="objectFull"
                                  @close="closeCard()"
            />
            <cbs-object-mount v-else-if="meta() === 'mount'"
                              ref="refMount"
                              :object-full="objectFull"
                              @close="closeCard()"
            />
            <cbs-object-report v-else-if="meta() === 'report'"
                               ref="refReport"
                               :object-full="objectFull"
                               @close="closeCard()"
            />
            <cbs-form v-else-if="meta() === 'form'"
                           ref="refWebreport"
                           :object-full="objectFull"
                           @close="closeCard()"
            />
            <div v-else>
              <cbs-table-view-param v-if="objectFull.object.sid !== 'tableviewparam'"
                                    hide
                                    :object="objectFull.object"
                                    @applydefault="onApplyParam"
              />
              <cbs-object-table v-if="isViewParam"
                                ref="refTable"
                                :objectid="objectFull.object.id"
                                :filter="resFilter()"
                                :prop-groupby="prmGroupby"
                                :prop-orderby="prmOrderby"
                                :domainsid="domainsid"
                                :row-buttons="rowButtons"
                                :row-dropdown-buttons="rowDropdownButtons"
                                :object-full="objectFull"
                                :hidden-fields="propHiddenFields()"
                                @clickRowButton="onClickRowButton"
              />
            </div>
          </b-card-body>
        </cbs-card-actions>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import useJwt from '@/cubus/jwt/useJwt'
import {
  BCardBody, BCol, BRow, VBToggle,
} from 'bootstrap-vue'
import CbsCardActions from '@/cubus/components/cbs-card-actions/CbsCardActions.vue'
import Ripple from 'vue-ripple-directive'
import CbsObjectTable from '@/cubus/components/object/CbsObjectTable.vue'
import CbsObjectImport from '@/cubus/components/object-import/CbsObjectImport.vue'
import CbsObjectProcedure from '@/cubus/components/object-procedure/CbsObjectProcedure.vue'
import CbsObjectReport from '@/cubus/components/object-report/CbsObjectReport.vue'
import CbsObjectMount from '@/cubus/components/object-mount/CbsObjectMount.vue'
import CbsTableViewParam from '@/cubus/components/table-view-param/CbsTableViewParam.vue'
import CbsForm from '@/cubus/components/cbs-form/CbsForm.vue'

export default {
  name: 'CbsObject',
  components: {
    CbsObjectImport,
    CbsObjectTable,
    BRow,
    BCol,
    BCardBody,
    CbsCardActions,
    CbsObjectProcedure,
    CbsObjectReport,
    CbsObjectMount,
    CbsTableViewParam,
    CbsForm,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
    rowButtons: {
      type: Array,
      default: () => ([]),
    },
    rowDropdownButtons: {
      type: Array,
      default: () => ([]),
    },
    domainsid: {
      type: String,
      default: '',
    },
    filter: {
      type: Object,
      default: () => ({
        node: {
          isactive: false,
          isexpanded: false,
          name: 'Root Node',
          oper: { id: 1, sid: 'and', name: 'AND' },
          nodes: [],
          conds: [],
        },
      }),
    },
    objectFull: {
      type: Object,
      default: null,
    },
    hiddenFields: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'close',
    'clickRowButton',
  ],
  data() {
    return {
      query: {},
      thread: {},
      isNew: false,
      isSearch: false,
      newRecord: {},
      search: null,
      isViewParam: false,
      prmFilter: null,
      prmGroupby: null,
      prmOrderby: null,
      prmPagesize: null,
      prmFields: null,
    }
  },
  computed: {},
  created() {},
  methods: {
    init() {},
    refreshStop(cardName) {
      this.init()
      setTimeout(() => {
        this.$refs[cardName].showLoading = false
      }, 1000)
    },
    closeCard() {
      console.log('cbs object : close card')
      this.$emit('close')
    },
    realFields() {
      return this.fields.filter(fld => fld.key !== 'actions')
    },
    toggleNewRecord() {
      if (this.isNew) {
        this.clearNew()
      } else {
        this.newRecord = this.getNewRecord()
        this.isNew = true
        console.log('newRecord', this.newRecord)
      }
    },
    clearNew() {
      this.isNew = false
      this.newRecord = {}
    },
    toggleSearch() {
      this.isSearch = !this.isSearch
    },
    getNewRecord() {
      const n = {}
      for (let i = 0; i < this.realFields().length; i += 1) {
        const fld = this.realFields()[i]
        n[fld.key] = fld.default
      }
      // console.log('new record', n)
      return JSON.parse(JSON.stringify(n))
    },
    closeNew() {
      this.$refs.btnNew.click()
    },
    onSave(record, rowIndex) {
      console.log('save record', record)
      console.log('save index', rowIndex)
      this.saveRecord(record, rowIndex)
    },
    saveRecord(record, rowIndex) {
      this.records.splice(rowIndex, 1, record)
      this.records[rowIndex].status = 'changed'
      // eslint-disable-next-line no-underscore-dangle
      this.records[rowIndex]._showDetails = false
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'saverecord',
          param: {
            objectid: this.objectid,
            record,
          },
        },
      })
        .then(response => {
          console.log('save record response', response)
          if (response.data.record) {
            const rec = response.data.record
            rec.status = 'saved'
            this.records.splice(rowIndex, 1, rec)
          }
        })
        .catch(error => {
          console.log('sqve record error', error)
        })
    },
    getRecordClone(record) {
      return JSON.parse(JSON.stringify(record))
      // console.log('clone1', clone)
      // eslint-disable-next-line no-restricted-syntax
      /*
      for (const prop in clone) {
        if (!prop.startsWith('_')) {
          // console.log('key', prop)
          clone[prop].init = clone[prop].value
        }
      }
      return JSON.parse(JSON.stringify(clone))
      */
    },
    onClickRowButton(btn, row) {
      // console.log('clickRowButton', btn, row)
      this.$emit('clickRowButton', btn, row)
    },
    object() {
      if (this.objectFull) {
        return this.objectFull.object
      }
      return null
    },
    objectid() {
      if (this.object()) {
        return this.object().id
      }
      return null
    },
    descr() {
      const obj = this.object()
      if (obj) {
        return obj.descr
      }
      return 'no_descr'
    },
    meta() {
      return this.objectFull.object.meta
    },
    onApplyParam(prm) {
      if (prm.status === 'default') {
        // console.log('onApplyParam', prm)
        this.prmFilter = prm.filter
        this.prmGroupby = prm.groupby
        this.prmOrderby = prm.orderby
        this.prmPagesize = prm.pagesize
        this.prmFields = prm.fields
      }
      this.isViewParam = true
    },
    resFilter() {
      if (this.filter !== {}) {
        return this.filter
      }
      return this.prmFilter
    },
    propHiddenFields() {
      const h = [...this.hiddenFields]
      if (!this.prmFields) return h
      this.prmFields.forEach(fld => {
        if (fld.visible === false && this.hiddenFields.includes(fld.key) === false) {
          this.hiddenFields.push(fld.key)
        }
      })
      return h
    },
  },
}
</script>

<style scoped>
</style>
