<template>
  <div>
    <cbs-record-form hide :object="objectFull" :fields="fields" :record="record" :compare="srcRecord" />

    <b-form-file
      v-model="files"
      placeholder="Choose a file or drop it here..."
      drop-placeholder="Drop file here..."
      multiple
    />

    <cbs-zipper :ref="'zipper_' + uuid" :files="files" @zipready="onZip" />

    <!-- buttons -->
    <b-button-toolbar
      key-nav
      aria-label="Toolbar with button groups"
      class="demo-inline-spacing"
    >
      <b-button-group size="sm" class="cbs-inline-spacing">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="danger"
          @click="onCancel"
        >
          <feather-icon icon="XIcon" />
          {{t('Cancel')}}
        </b-button>
      </b-button-group>
      <b-button-group size="sm" class="cbs-inline-spacing">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="warning"
          @click="clearFiles"
        >
          <feather-icon icon="DeleteIcon" />
          {{t('Clear')}}
        </b-button>
      </b-button-group>
      <b-button-group size="sm" class="cbs-inline-spacing">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="success"
          :disabled="files.length === 0 || status === 'process'"
          @click="upload"
        >
          <feather-icon icon="UploadIcon" />
          {{t('Upload')}}
        </b-button>
      </b-button-group>
      <b-button-group size="sm" class="cbs-inline-spacing">
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="showLog ? 'primary' : 'outline-primary'"
            @click="showLog = !showLog"
        >
          <feather-icon icon="EyeIcon" />
          {{t('Log')}}
        </b-button>
        <b-button v-if="isAdmin()"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :variant="isSettings ? 'primary' : 'outline-primary'"
          @click="isSettings = !isSettings"
        >
          <feather-icon icon="SettingsIcon" />
          Settings
        </b-button>
        <b-button v-if="isAdmin()"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :variant="isDebug ? 'primary' : 'outline-primary'"
          @click="isDebug = !isDebug"
        >
          <feather-icon icon="CodeIcon" />
          {{ $options.name }}
        </b-button>
      </b-button-group>
    </b-button-toolbar>
    <!-- /buttons -->

    <!-- thread log -->
    <cbs-thread :ref="'thread_' + uuid" :thread="thread" :pre-thread="preThread" />
    <!-- /thread log -->

    <!-- settings -->
    <div v-if="isSettings" style="border: solid #cccccc 1px; border-radius: 5px; margin-top: 20px; padding: 10px;">
      <!-- <cbs-object-table objectsid="objectparam" :filter="settingsFilter()" /> -->
      <cbs-ctrl-relation objectsid="objectparam" :filter="settingsFilter()" :hidden-fields="['jsonfilter']" />
    </div>
    <!-- /settings -->

    <!-- modal -->
    <div>
      <b-button v-show="false" ref="modalComplete"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-remove
                variant="outline-primary"
      >
        Message Form
      </b-button>
      <b-modal
        id="modal-remove"
        ok-only
        ok-title="OK"
        centered
        title="Message"
        @ok="onModalClick"
      >
        Import completed.
      </b-modal>
    </div>
    <!-- /modal -->

    <!-- modal error -->
    <div>
      <b-button v-show="false"
                :ref="'btn_modal_error_' + uuid"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal="'modal_error_' + uuid"
                variant="outline-primary"
      >
        Error
      </b-button>
      <b-modal
        :id="'modal_error_' + uuid"
        ok-only
        ok-title="OK"
        ok-variant="danger"
        centered
        title="Error"
        modal-class="modal-danger"
        @ok="onErrorClick"
      >
        {{ importError }}
      </b-modal>
    </div>
    <!-- /modal error -->

    <b-row>
      <b-col>
        <div style="margin-top:10px;">
          <!-- debug -->
          <div v-if="isDebug" style="margin:10px">
            Props: <json-viewer :value="getProps()" :expand-depth=0 sort/>
            Data: <json-viewer :value="getData()" :expand-depth=0 sort/>
            Session: <json-viewer :value="session()" :expand-depth=0 sort/>
          </div>
          <!-- /debug -->
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BButtonGroup, BButtonToolbar, BCol, BFormFile, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import JsonViewer from 'vue-json-viewer'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import CbsCtrlRelation from '@/cubus/components/relation/CbsCtrlRelation.vue'
import CbsRecordForm from '@/cubus/components/record/CbsRecordForm.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import CbsThread from '@/cubus/components/thread/CbsThread.vue'
import CbsZipper from '@/cubus/components/zipper/CbsZipper.vue'

export default {
  name: 'CbsObjectImport',
  components: {
    CbsCtrlRelation,
    BButton,
    JsonViewer,
    BFormFile,
    BButtonToolbar,
    BButtonGroup,
    BRow,
    BCol,
    CbsRecordForm,
    CbsThread,
    CbsZipper,
  },
  directives: {
    Ripple,
  },
  props: {
    object: {
      type: Object,
      default: () => ({}),
    },
    objectFull: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t, // i18n
    }
  },
  emits: [
    'cancel',
  ],
  data() {
    return {
      isDebug: false,
      fields: [],
      record: null,
      srcRecord: null,
      files: [],
      threadLog: [],
      thread: {
        info: null,
      },
      preThread: {
        currenttask: 'idle',
        start: 0,
        finish: 0,
        progerss: 0,
        status: 'idle',
      },
      isSettings: false,
      showLog: false,
      uuid: null,
      importError: null,
      zippedFile: null,
      status: 'idle',
      chunkSize: 1024 * 1024, // Размер части в байтах (1 MB)
      chunkUuid: 'init',
    }
  },
  created() {
    this.init()
  },
  methods: {
    // eslint-disable-next-line no-underscore-dangle
    getData() { return this._data },
    // eslint-disable-next-line no-underscore-dangle
    getProps() { return this._props },
    session() {
      return this.$store.getters['cubus-store/SESSION']
    },
    init() {
      this.uuid = useCubus.guid()
      this.initRecord()
    },
    initRecord() {
      useJwt.query({
        query: {
          method: 'newrecord',
          param: {
            objectid: this.object.id,
          },
        },
      })
        .then(response => {
          console.log('newrecord response', response)
          if (response.data) {
            if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            }
            if (response.data.thread) {
              this.delayNewRecord(response.data.thread)
            }
          }
        })
        .catch(error => {
          console.log('newrecord error', error)
          useCubus.toastError(this, error)
        })
    },
    delayNewRecord(thread) {
      setTimeout(() => this.loadThreadNewRecord(thread), 500)
    },
    loadThreadNewRecord(threadName) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'thread',
          param: {
            threadname: threadName,
          },
        },
      })
        .then(response => {
          console.log('thread response', response)
          if (response.data) {
            if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            } else if (response.data.thread) {
              if (response.data.thread.status === 'done' && response.data.thread.result) {
                console.log('new record result', response.data.thread.result)
                this.fields = response.data.thread.result.record.columns
                this.record = response.data.thread.result.record.record
                this.srcRecord = JSON.parse(JSON.stringify(response.data.thread.result.record.record))
              } else {
                this.delayThreadNewRecord(threadName)
              }
            }
          } else this.delayThreadNewRecord(threadName)
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    showFiles() {
      console.log('files', this.files)
    },
    onCancel() {
      this.$emit('close')
    },
    clearFiles() {
      this.files = []
    },
    isSaveable() {
      return this.recordStatus() !== 'init' && this.recordState()
    },
    recordState() {
      return this.fields.some(fld => !this.fldState(fld).state)
    },
    recordStatus() {
      if (this.fields.some(fld => this.cellStatus(fld) === 'changed')) {
        return 'changed'
      }
      return 'init'
    },
    isAdmin() {
      return useCubus.isAdmin()
    },
    onUpload() {
      this.threadLog = []
      // this.record = this.$refs.recordForm.getRecord()
      const formData = new FormData()
      const filekeys = []
      this.files.forEach(file => {
        const key = `file${this.files.indexOf(file)}`
        filekeys.push(key)
        formData.append(key, file)
      })
      formData.append('query', JSON.stringify({
        method: 'import',
        param: {
          objectid: this.object.id,
          record: this.record,
          fields: this.fields,
          attachments: this.files.length,
          filekeys,
        },
      }))
      console.log('formData ready', formData)
      useJwt.upload(
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
        .then(response => {
          console.log('upload response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread) {
            this.delayUpload(response.data.thread)
          }
        })
        .catch(error => {
          console.log('upload error', error)
          useCubus.toastError(this, error)
        })
    },
    send() {
      // this.send_v1()
      this.send_v2()
    },
    send_v1() {
      this.threadLog = []
      // this.record = this.$refs.recordForm.getRecord()
      const formData = new FormData()
      const filekeys = []
      /*
      this.files.forEach(file => {
        const key = `file${this.files.indexOf(file)}`
        filekeys.push(key)
        formData.append(key, file)
      })
      */
      const key = 'file0'
      filekeys.push(key)
      formData.append(key, this.zippedFile)
      formData.append('query', JSON.stringify({
        method: 'import',
        param: {
          objectid: this.object.id,
          record: this.record,
          fields: this.fields,
          attachments: this.files.length,
          filekeys,
        },
      }))
      console.log('formData ready', formData)
      useJwt.upload(
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
        .then(response => {
          console.log('upload response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread) {
            this.delayUpload(response.data.thread)
          }
        })
        .catch(error => {
          console.log('upload error', error)
          useCubus.toastError(this, error)
        })
    },
    send_v2() {
      this.threadLog = []
      // this.record = this.$refs.recordForm.getRecord()
      /* const formData = new FormData()
      const filekeys = []
      const key = 'file0'
      filekeys.push(key)
      formData.append(key, this.zippedFile)
      formData.append('query', JSON.stringify({
        method: 'import',
        param: {
          objectid: this.object.id,
          record: this.record,
          fields: this.fields,
          attachments: this.files.length,
          filekeys,
        },
      }))
      console.log('formData ready', formData ) */
      /* useJwt.upload(
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      ) */
      this.uploadChunks()
        .then(response => {
          console.log('upload response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.record && response.data.record.thread) {
            this.delayUpload(response.data.record.thread)
          }
        })
        .catch(error => {
          console.log('upload error', error)
          useCubus.toastError(this, error)
        })
    },
    async uploadChunks() {
      const chunkUuid = useCubus.guid()
      this.threadLog = []
      const totalChunks = Math.ceil(this.zippedFile.size / this.chunkSize)
      let response = null
      // eslint-disable-next-line no-plusplus
      for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
        const start = chunkIndex * this.chunkSize
        const end = Math.min(start + this.chunkSize, this.zippedFile.size)
        const chunk = this.zippedFile.slice(start, end)
        // eslint-disable-next-line no-await-in-loop
        response = await this.uploadChunk(chunk, chunkIndex, totalChunks, chunkUuid)
        console.log(`response : ${chunkIndex} : ${chunkUuid}`, response)
        // this.chunkUuid = response.data.chunk.uuid
        console.log(`Chunk ${chunkIndex + 1} of ${totalChunks} uploaded.`)
      }
      return response
    },
    async uploadChunk(chunk, chunkIndex, totalChunks, chunkUuid) {
      let response = null
      const formData = new FormData()
      const filekeys = []
      const key = 'file0'
      filekeys.push(key)
      formData.append(key, chunk)
      formData.append('query', JSON.stringify({
        method: 'import',
        param: {
          chunk: {
            uuid: chunkUuid,
            index: chunkIndex,
            total: totalChunks,
          },
          objectid: this.object.id,
          record: this.record,
          fields: this.fields,
          attachments: this.files.length,
          filekeys,
        },
      }))
      try {
        // eslint-disable-next-line no-await-in-loop
        response = await useJwt.upload(
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
      } catch (error) {
        console.log('upload error', error)
        useCubus.toastError(this, error)
      }
      return response
    },
    upload() {
      // this.upload_v1()
      this.upload_v2()
    },
    upload_v1() {
      this.status = 'process'
      this.preThread = {
        currenttask: 'Preparing files...',
        start: Date.now(),
        progress: 0,
        finish: 0,
        status: 'process',
      }
      this.$nextTick(() => {
        // Этот код выполнится после обновления DOM в следующем тике
        // console.log('Пропущен тик')
        this.$refs[`thread_${this.uuid}`].timer()
      })
      this.$refs[`zipper_${this.uuid}`].zip()
    },
    async upload_v2() {
      this.status = 'process'
      this.preThread = {
        currenttask: 'Preparing files...',
        start: Date.now(),
        progress: 0,
        finish: 0,
        status: 'process',
      }
      this.$nextTick(() => {
        // Этот код выполнится после обновления DOM в следующем тике
        // console.log('Пропущен тик')
        this.$refs[`thread_${this.uuid}`].timer()
      })
      // this.$refs[`zipper_${this.uuid}`].zip()
      const zipBlob = await useCubus.zipFiles(this.files)
      this.onZip(zipBlob)
    },
    delayUpload(thread) {
      setTimeout(() => this.threadUpload(thread), 1000)
    },
    threadUpload(thread) {
      useJwt.query({
        query: {
          method: 'thread',
          param: { threadname: thread },
        },
      })
        .then(response => {
          console.log('thread upload response', response)
          if (response.data && response.data.thread) {
            this.thread = response.data.thread
            if (response.data.thread.log) {
              this.threadLog = response.data.thread.log
            }
            if (response.data.thread.status === 'error') {
              // useCubus.toastError(this, response.data.thread.error || 'Unknown error')
              this.importError = response.data.thread.error
              this.$refs[`btn_modal_error_${this.uuid}`].click()
              this.status = 'error'
            } else if (response.data.thread.status === 'done') {
              this.$refs.modalComplete.click()
              this.status = 'done'
            } else {
              this.delayUpload(thread)
            }
          } else {
            useCubus.toastError(this, 'Thread not found.')
          }
        })
        .catch(error => {
          console.log('thread upload error', error)
          useCubus.toastError(this, error)
        })
    },
    onModalClick() {},
    onErrorClick() {},
    settingsFilter() {
      return {
        isActive: true,
        node: {
          isactive: true,
          oper: { sid: 'and', name: 'And' },
          nodes: [],
          conds: [
            {
              isactive: true,
              oper: {
                sid: 'equal',
                name: '=',
              },
              args: [
                {
                  type: {
                    sid: 'entitysid',
                    name: 'EntitySid',
                  },
                  value: 'object',
                },
                {
                  type: {
                    sid: 'value',
                    name: 'Value',
                  },
                  value: `${this.object.id}`,
                },
              ],
            },
          ],
        },
      }
    },
    onZip(zipBlob) {
      console.log('zipBlob ready')
      const filename = `import_${this.uuid}.zip`
      this.zippedFile = new File([zipBlob], filename, { type: 'application/zip' })
      this.preThread.finish = Date.now()
      this.preThread.progress = 100
      this.preThread.currenttask = 'Preparing done. Uploading files...'
      this.preThread.status = 'done'
      this.send()
    },
  },
}
</script>

<style scoped>

</style>
