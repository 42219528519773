<template>
<div>
  <!--<h1>CbsForm</h1>-->
  <asl-projects v-if="objectFull.object.sid === 'asl_projects'" :object-full="objectFull" />
  <cbs-debug :context="this" />
</div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import AslProjects from '@/cubus/components/cbs-form/custom-forms/als-projects/AslProjects.vue'

export default {
  name: 'CbsForm',
  components: {
    CbsDebug,
    AslProjects,
  },
  props: {
    objectFull: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style scoped>

</style>
