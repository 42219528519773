<template>
  <div class="mb-1">
    <cbs-collapse :trigger="true">
      <b-row>
        <b-col cols="12" align-self="end">
          <b-dropdown
              :text="value.type ? value.type.name : 'Select Type:'"
              :variant="value.type ? 'flat-primary' : 'flat-warning'"
              size="sm"
          >
            <b-dropdown-item v-for="(type, index) in types" :key="'argtype' + index" @click="selectType(type.sid)">
              {{ type.name }}
            </b-dropdown-item>
          </b-dropdown>
          <b-button size="sm" variant="flat-danger" @click="deleteMe"><feather-icon icon="Trash2Icon" /></b-button>
          <div v-if="value.type" class="h-100">
            <div v-if="value.type.sid === 'entity' || value.type.sid === 'paramentity' || value.type.sid === 'sourceentity'">
              <cbs-reference-picker-sid entitysid="entity" :value-container="value" value-name="value" />
            </div>
            <div v-else-if="value.type.sid === 'formula'">
              <cbs-reference-picker-sid entitysid="formula" :value-container="value" value-name="value" />
            </div>
            <div v-else-if="value.type.sid === 'monad'" class="border border-secondary rounded p-1">
              <cbs-function :value="value.value" />
            </div>
            <b-form-input v-else placeholder="Enter value" v-model="value.value" />
            <div v-if="false">
              <b-form-checkbox v-if="value.datatype === 'bit'"
                               v-model="argument.value"
                               @change="onChange"
              />
              <div v-else-if="value.entitysid === 'parent'">
                <v-select v-model="argument.value"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="title"
                          :options="getParentRef()"
                          @input="onInputVSelect($event,index)"
                          :class="selectClass(fld)"
                />
                <div class="text-danger" style="font-size:0.857rem;margin-top:0.25rem;">
                  {{ fldState(fld).status }}
                </div>
                <b-form-invalid-feedback>
                  {{ fldState(fld).status }}
                </b-form-invalid-feedback>
                <div class="invalid-feedback">
                  {{ fldState(fld).status }}
                </div>
              </div>
              <v-select v-else-if="value.datatype === 'ref'"
                        v-model="argument.value"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="getEntRef()"
                        :filter-by="filterBy"
              />
              <span v-else-if="value.datatype === 'datetime'">
                <b-input-group class="mb-1">
                  <b-form-input
                      v-model="argument.value"
                      type="text"
                      placeholder="YYYY-MM-DD"
                      autocomplete="off"
                      show-decade-nav
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                        v-model="argument.value"
                        show-decade-nav
                        button-only
                        button-variant="outline-primary"
                        right
                        size="sm"
                        locale="en-US"
                        aria-controls="example-input"
                        @context="onContext"
                    />
                  </b-input-group-append>
                </b-input-group>
              </span>
              <b-form-input v-else placeholder="Enter value" v-model="value.value" />
            </div>
          </div>
          <b-form-input v-else disabled placeholder="Enter value" v-model="value.value" />
          <b-form-group v-if="false && !value.type">
            <v-select v-model="value.oper"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="opers"
                      :filter-by="filterBy"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </cbs-collapse>
  </div>
</template>

<script>
import {
  BButton, BCol, BDropdown, BDropdownItem, BFormGroup, BFormInput, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'
import CbsReferencePickerSid from '@/cubus/components/reference/CbsReferencePickerSid.vue'
// import CbsFunction from '@/cubus/components/funciton/CbsFunction.vue'

export default {
  name: 'CbsFunctionArgument',
  components: {
    // CbsFunction,
    CbsFunction: () => import('@/cubus/components/funciton/CbsFunction.vue'),
    CbsCollapse,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BFormInput,
    vSelect,
    CbsReferencePickerSid,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    types: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: null,
    },
  },
  emits: [
    'delete',
  ],
  data() {
    return {
      opers: [
        { title: 'AND', sid: 'and' },
        { title: 'OR', sid: 'or' },
        { title: 'NOT', sid: 'not' },
      ],
      isShow: false,
    }
  },
  created() {
    this.isShow = true
  },
  methods: {
    filterBy(option, label, search) {
      const pattern = `.*${search.replace(new RegExp(' ', 'gi'), '.*').replace(new RegExp('\\.\\*\\.\\*', 'gi'), ' ')}.*`
      const re = new RegExp(pattern, 'gi')
      return re.test(label || '')
    },
    deleteMe() {
      this.isShow = false
      this.$emit('delete', this.index)
    },
    selectType(sid) {
      this.value.type = this.types.find(t => t.sid === sid)
      if (this.value.type.sid === 'monad') {
        this.value.value = this.getNewMonad()
      } else {
        this.value.value = null
      }
      this.$emit('selecttype', { index: this.index, sid })
    },
    onChange() {},
    getNewMonad() {
      return {
        oper: null,
        args: [],
      }
    },
  },
}
</script>

<style scoped>

</style>
