<template>
  <div>
    <b-row>
      <b-col cols="12">
        <cbs-card-actions ref="mainCard"
                          :title="title"
                          @refresh="refreshStop('mainCard')"
        >
          <b-card-body>

            <!-- search -->
            <div style="margin-bottom:10px;">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input placeholder="Search" v-model="search" />
              </b-input-group>
            </div>
            <!-- /search -->

            <b-table responsive small
                     :fields="fields"
                     :items="objects"
                     :filter="search"
            >
              <template #cell(actions)="data">
                <b-button-group
                    size="sm"
                    class="cbs-inline-spacing mb-1"
                >
                  <b-button size="sm"
                            variant="flat-primary"
                            target="_blank"
                            :href="'/cubus/object/' + data.item.id"
                            title="Open in new tab"
                  >
                    <feather-icon icon="ExternalLinkIcon" />
                  </b-button>
                  <b-button size="sm"
                            variant="flat-primary"
                            :href="'/cubus/object/' + data.item.id"
                            title="Open object"
                  >
                    <feather-icon icon="LinkIcon" />
                  </b-button>
                  <b-button size="sm"
                            variant="flat-primary"
                            @click="openObjectBelow(data.item.id)"
                            title="Open object below"
                  >
                    <feather-icon icon="CornerDownLeftIcon" />
                  </b-button>
                </b-button-group>
              </template>
            </b-table>
          </b-card-body>
        </cbs-card-actions>
      </b-col>
    </b-row>

    <!-- cards -->
    <div v-for="(card, index) in cards" :key="index">
      <cbs-ctrl-object :objectid="card.objectid" @close="removeCard(index)" />
      <!--<cbs-object
        :objectid="card.objectid"
        @close="removeCard(index)"
      />-->
    </div>
    <!-- /cards -->
  </div>
</template>

<script>
import {
  BCardBody, BCol, BRow, BTable, BButton, BInputGroup, BInputGroupPrepend, BFormInput, BButtonGroup,
} from 'bootstrap-vue'
import useJwt from '@/cubus/jwt/useJwt'
import CbsCardActions from '@/cubus/components/cbs-card-actions/CbsCardActions.vue'
import useCubus from '@/cubus/services/useCubus'
import CbsCtrlObject from '@/cubus/components/object/CbsCtrlObject.vue'

export default {
  name: 'CbsObjectsTbl',
  components: {
    // CbsObject: () => import('@/cubus/components/object/CbsObject.vue'),
    BTable,
    BRow,
    BCol,
    BCardBody,
    BButton,
    CbsCardActions,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    CbsCtrlObject,
    BButtonGroup,
  },
  props: {
    metaFilter: {
      type: String,
      default: 'all',
    },
    title: {
      type: String,
      default: 'OBJECTS',
    },
  },
  data() {
    return {
      objects: [],
      fields: [
        'actions',
        {
          key: 'id',
          sortable: true,
        },
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'sid',
          sortable: true,
        },
        {
          key: 'meta_name',
          sortable: true,
        },
        {
          key: 'descr',
        },
      ],
      cardSet: new Set(),
      cardArr: [],
      cards: [],
      search: null,
    }
  },
  computed: {},
  created() {
    this.loadObjects()
  },
  methods: {
    loadObjects() {
      console.log('load objects')
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'objects',
          param: { filter: this.metaFilter },
        },
      })
        .then(response => {
          console.log('objects response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.objects) {
            this.objects = response.data.objects
          }
        })
        .catch(error => {
          console.log('objects error', error)
        })
    },
    openObjectBelow(objectid) {
      // this.cardSet.add(objectid)
      // this.initCardArr()
      this.addCard(objectid)
      this.$refs.mainCard.triggerCollapse()
    },
    addCard(objectid) {
      if (!this.isCardExists(objectid)) {
        this.cards.push({ objectid })
      }
    },
    isCardExists(objectid) {
      return this.cards.filter(card => card.objectid === objectid).length > 0
    },
    refreshStop(cardName) {
      this.loadObjects()
      setTimeout(() => {
        this.$refs[cardName].showLoading = false
      }, 1000)
    },
    closeCard(objectid) {
      this.cardSet.delete(objectid)
      // console.log('cardSet', this.cardSet)
      // this.cardArr = []
      // this.initCardArr()
    },
    initCardArr() {
      this.cardArr = []
      this.cardSet.forEach(el => {
        this.cardArr.push({ objectid: el })
      })
      // console.log('cardArr', this.cardArr)
    },
    removeCard(index) {
      console.log('index', index)
      console.log('cards before', this.cards)
      this.cards.splice(index, 1)
      console.log('cards after', this.cards)
    },
  },
}
</script>

<style scoped>
</style>
