<template>
  <div class="pl-1 pr-1">
    <cbs-filter-node v-if="filter && filter.node" :node="filter.node" :fields="workingFields()" :param-fields="workingParamFields()" root />
    <div class="mt-1"></div>
    <cbs-debug v-if="isDebug && isAdmin()" :context="this" />
  </div>
</template>

<script>
import useCubus from '@/cubus/services/useCubus'
import Ripple from 'vue-ripple-directive'
import CbsFilterNode from '@/cubus/components/filter/CbsFilterNode.vue'
import useJwt from '@/cubus/jwt/useJwt'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'

export default {
  name: 'CbsFilter',
  components: {
    CbsDebug,
    CbsFilterNode,
  },
  directives: {
    Ripple,
  },
  props: {
    filter: {
      type: Object,
      default: () => ({
        node: {
          oper: {
            id: 1,
            sid: 'and',
            name: 'AND',
          },
          isactive: false,
          isexpanded: false,
          nodes: [],
          conds: [],
        },
      }),
    },
    fields: {
      type: Array,
      default: () => [],
    },
    paramFields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isDebug: true,
      allFields: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      // this.initAllFields()
    },
    initAllFields() {
      if (!this.fields || this.fields.length === 0) {
        this.loadFields()
      }
    },
    loadFields() {
      useJwt.query({
        query: {
          method: 'fields',
          param: {},
        },
      })
        .then(response => {
          console.log('response', response)
          if (response.data.thread) {
            this.threadFields(response.data.thread)
          } else if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else {
            useCubus.toastError(this, 'No thread provided for loading fields.')
          }
        })
        .catch(error => {
          console.log('error', error)
          useCubus.toastError(this, error)
        })
    },
    delayFields(thread) { setTimeout(() => this.threadFields(thread), 500) },
    threadFields(thread) {
      useJwt.query({
        query: {
          method: 'thread',
          param: { threadname: thread },
        },
      })
        .then(response => {
          console.log('response', response)
          if (response.data.thread.status === 'done') {
            this.allFields = response.data.thread.result.fields
          } else if (response.data.thread.status === 'error') {
            useCubus.toastError(this, response.data.thread.error)
          } else {
            this.delayFields(thread)
          }
        })
        .catch(error => {
          console.log('error', error)
        })
    },
    isAdmin() {
      return useCubus.isAdmin()
    },
    session() {
      return this.$store.getters['cubus-store/SESSION']
    },
    onCancel() {},
    onOk() {},
    workingFields() {
      return this.fields
      /*
      if (this.fields && this.fields.length > 0) {
        return this.fields
      }
      return this.allFields
      */
    },
    workingParamFields() {
      return this.paramFields
      /*
      if (this.paramFields && this.paramFields.length > 0) {
        return this.paramFields
      }
      return this.allFields
      */
    },
  },
}
</script>

<style scoped>

</style>
