<template>
  <div>
    <b-row class="mb-2">
      <b-col v-for="(fld, index) in fields" :key="index" :md="fieldWidth(fld)" class="mb-1">
        <b-form-group :description="fld.descr">
          <div v-if="record[fld.key]">
            <label :for="fld.key + 'Label'"
                   :class="cellStatus(fld) === 'changed' ? 'text-warning' : ''"
            >
              {{ fld.label }}
              <span v-if="fld.isrequired === true">*</span>
              <span v-if="fld.isunique === true">!</span>
            </label>
            <small v-if="cellStatus(fld) === 'changed'" class="text-warning">
              &nbsp;(changed)
            </small>
            <b-form-input v-if="fld.datatype === 'id'"
                          :id="fld.key + 'Label'"
                          v-model="record[fld.key].value"
                          placeholder="Place for ID"
                          disabled
            />

            <!-- parent -->
            <div v-else-if="fld.entitysid === 'parent'">
              <v-select v-model="record[fld.key]"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="refs[fld.parententityid]"
                        :class="selectClass(fld)"
                        @input="onInputVSelect($event,index)"
              />
              <div class="text-danger" style="font-size:0.857rem;margin-top:0.25rem;">
                {{ fldState(fld).status }}
              </div>
              <b-form-invalid-feedback>
                {{ fldState(fld).status }}
              </b-form-invalid-feedback>
              <div class="invalid-feedback">
                {{ fldState(fld).status }}
              </div>
            </div>
            <!-- /parent -->

            <b-form-input v-else-if="fld.datatype === 'int'"
                          :id="fld.key + 'Label'"
                          v-model="record[fld.key].value"
                          placeholder="Input integer value"
                          type="number"
                          :formatter="formatter"
            />

            <div v-else-if="fld.rendertype === 'jsonfilter'" style="padding:10px;border: solid #cccccc 1px;border-radius:5px;">
              <cbs-filter :filter="record[fld.key].value"
                          :fields="filterFields()"
                          :param-fields="filterParamFields()"
              />
            </div>
            <div v-else-if="fld.rendertype === 'jsongroupby'" style="padding:10px;border: solid #cccccc 1px;border-radius:5px;">
              <cbs-groupby :groupby="record[fld.key].value"
                           :fields="filterFields()"
              />
            </div>
            <div v-else-if="fld.rendertype === 'jsonorderby'" style="padding:10px;border: solid #cccccc 1px;border-radius:5px;">
              <cbs-orderby :orderby="record[fld.key].value"
                           :fields="filterFields()"
              />
            </div>

            <div v-else-if="fld.rendertype === 'jsonmodification'" style="padding:10px;border: solid #cccccc 1px;border-radius:5px;">
              <cbs-modification-set :value="record[fld.key].value"
                                    :fields="destinationFields()"
              />
            </div>

            <b-form-textarea v-else-if="fld.datatype === 'json'"
                             :id="fld.key + 'Label'"
                             v-model="record[fld.key].value"
                             placeholder="Textarea"
                             rows="3"
            />
            <b-form-textarea v-else-if="fld.datatype === 'text'"
                             :id="fld.key + 'Label'"
                             v-model="record[fld.key].value"
                             placeholder="Textarea"
                             rows="3"
            />
            <b-form-checkbox v-else-if="fld.datatype === 'bit'"
                             v-model="record[fld.key].value"
            />

            <!-- ref -->
            <div v-else-if="fld.datatype === 'ref'">
              <v-select v-model="record[fld.key]"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="refs[fld.entityid]"
                        :class="selectClass(fld)"
                        :filter-by="filterBy"
                        @input="onInputVSelect($event,index)"
              />
              <div class="text-danger" style="font-size:0.857rem;margin-top:0.25rem;">
                {{ fldState(fld).status }}
              </div>
              <b-form-invalid-feedback>
                {{ fldState(fld).status }}
              </b-form-invalid-feedback>
              <div class="invalid-feedback">
                {{ fldState(fld).status }}
              </div>
            </div>
            <!-- /ref -->

            <!-- file -->
            <div v-else-if="fld.datatype === 'file'">
              <cbs-file-entity :field="fld"
                               :cell="record[fld.key]"
                               :initcell="initRecord[fld.key]"
                               :can-download="canDownload()"
                               :objectid="objectFull.object.id"
              />
            </div>
            <!-- /file -->

            <div v-else-if="fld.datatype === 'datetime'">
              <b-input-group class="mb-1">
                <b-form-input
                  id="example-input"
                  v-model="record[fld.key].value"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  show-decade-nav
                  :state="fldState(fld).state"
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="record[fld.key].value"
                    show-decade-nav
                    button-only
                    button-variant="outline-primary"
                    right
                    size="sm"
                    :locale="locale"
                    aria-controls="example-input"
                    @context="onContext"
                  />
                </b-input-group-append>
              </b-input-group>
              <div class="text-danger" style="font-size:0.857rem;margin-top:0.25rem;">
                {{ fldState(fld).status }}
              </div>
              <b-form-invalid-feedback>
                {{ fldState(fld).status }}
              </b-form-invalid-feedback>
              <div class="invalid-feedback">
                {{ fldState(fld).status }}
              </div>
            </div>

            <div v-else>
              <b-form-input :id="fld.key + 'Label'"
                            v-model="record[fld.key].value"
                            placeholder="Normal Input"
                            :state="fldState(fld).state"
                            @change="onChange(fld)"
              />
              <b-form-invalid-feedback>
                {{ fldState(fld).status }}
              </b-form-invalid-feedback>
            </div>

            <div v-if="fld.isunique === true" style="margin-top:5px;">
              <b-button v-if="false"
                        variant="flat-primary" size="sm"
                        :disabled="isEmpty(fld,record[fld.key])"
                        @click="checkUnique(fld,record[fld.key])"
              >
                <feather-icon icon="EyeIcon" /> Check unique
              </b-button>
            </div>

            <div v-if="isDebug">
              <p>Field: {{ field(fld.key) }}</p>
              <p>Cell: {{ record[fld.key] }}</p>
              <p>State: {{ fldState(fld) }}</p>
              <p>Cell Status: {{ cellStatus(fld) }}</p>
            </div>
          </div>
        </b-form-group>
      </b-col>
    </b-row>

    <div v-if="objectFull && objectFull.object.sid === 'mountstep'">
      <cbs-data-object v-if="record && record.sourceobjectid && record.sourceobjectid.value"
                       :ref="`cbsRecordCard-mountStep-srcObject_${uuid}`"
                       :hide="true"
                       :objectid="record.sourceobjectid.value"
                       @load="filterSrcObjReady"
      />
      <cbs-data-object v-if="record && record.destobjectid && record.destobjectid.value"
                       :ref="`cbsRecordCard-mountStep-destObject_${uuid}`"
                       :hide="true"
                       :objectid="record.destobjectid.value"
                       @load="filterDestObjReady"
      />
      <cbs-data-object v-if="record && record.objectid && record.objectid.value"
                       :ref="`cbsRecordCard-mountStep-object_${uuid}`"
                       :hide="true"
                       :objectid="record.objectid.value"
                       @load="filterParamObjReady"
      />
      <cbs-data-object hide objectsid="mountoperand" @load="loadMountOperObj" />
      <cbs-data-record v-if="mountOperObj && record.mountoperid && record.mountoperid.value"
                       :object-full="mountOperObj" hide :rowid="record.mountoperid.value" @load="loadMountOperRec"
      />
    </div>
    <div v-if="objectFull && objectFull.object.sid === 'scalar'">
      <cbs-data-object v-if="record && record.sourceobjectid && record.sourceobjectid.value"
                       :hide="true"
                       :objectid="record.sourceobjectid.value"
                       @load="filterSrcObjReady"
      />
    </div>
    <div v-if="objectFull && objectFull.object.sid === 'repexportquery'">
      <cbs-data-object v-if="record && record.objectid && record.objectid.value"
                       :hide="true"
                       :objectid="record.objectid.value"
                       @load="filterObjReady"
      />
    </div>

    <!-- debug -->
    <b-row v-if="false && !noDebug && isAdmin()">
      <b-col>
        <b-button-toolbar key-nav
                          aria-label="Toolbar with button groups"
                          class="demo-inline-spacing"
        >
          <b-button-group v-if="isAdmin()" size="sm" class="cbs-inline-spacing">
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      @click="isDebug = !isDebug"
            >
              <feather-icon icon="CodeIcon" /> {{ $options.name }}
            </b-button>
          </b-button-group>
        </b-button-toolbar>
        <div style="margin-top:10px;">
          <!-- debug -->
          <div v-if="isDebug" style="margin:10px">
            Props: <json-viewer :value="getProps()" :expand-depth="0" sort />
            Data: <json-viewer :value="getData()" :expand-depth="0" sort />
            Session: <json-viewer :value="session()" :expand-depth="0" sort />
            <p>isValid: {{ isValid() }}</p>
          </div>
          <!-- /debug -->
        </div>
      </b-col>
    </b-row>
    <!-- /debug -->

    <cbs-debug v-if="!noDebug && isAdmin()" :context="this" />
  </div>
</template>

<script>
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import {
  BButton, BButtonGroup, BButtonToolbar, BCol, BFormCheckbox, BFormDatepicker, BFormGroup, BFormInput, BFormInvalidFeedback,
  BFormTextarea, BInputGroup, BInputGroupAppend, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import JsonViewer from 'vue-json-viewer'
import CbsFileEntity from '@/cubus/components/file/CbsFileEntity.vue'
import CbsDataObject from '@/cubus/components/query/CbsDataObject.vue'
import CbsDataRecord from '@/cubus/components/query/CbsDataRecord.vue'
import CbsFilter from '@/cubus/components/filter/CbsFilter.vue'
import CbsGroupby from '@/cubus/components/groupby/CbsGroupby.vue'
import CbsOrderby from '@/cubus/components/orderby/CbsOrderby.vue'
import CbsModificationSet from '@/cubus/components/modification/CbsModificationSet.vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'

export default {
  name: 'CbsRecordFormOld',
  components: {
    CbsDebug,
    BFormInvalidFeedback,
    vSelect,
    BFormGroup,
    BCol,
    BRow,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormDatepicker,
    BButton,
    BButtonGroup,
    BButtonToolbar,
    JsonViewer,
    CbsFileEntity,
    BFormTextarea,
    CbsDataObject,
    CbsDataRecord,
    BFormCheckbox,
    CbsFilter,
    CbsGroupby,
    CbsOrderby,
    CbsModificationSet,
  },
  directives: {
    Ripple,
  },
  props: {
    objectFull: {
      type: Object,
      default: null,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    propRecord: {
      type: Object,
      default: () => {},
    },
    noDebug: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'change',
  ],
  data() {
    return {
      refs: {},
      record: JSON.parse(JSON.stringify(this.propRecord)),
      initRecord: JSON.parse(JSON.stringify(this.propRecord)),
      isDebug: false,
      filterObj: null,
      filterSrcObj: null,
      filterDestObj: null,
      filterParamObj: null,
      mountOperObj: null,
      mountOperRec: null,
    }
  },
  computed: {
    locale() {
      return useCubus.locale(this.$i18n.locale)
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.initRefs()
    },
    initRefs() {
      this.fields.filter(f => f.datatype === 'ref' || f.entitysid === 'parent').forEach(fld => {
        const entid = fld.datatype === 'ref' ? fld.entityid : fld.parententityid
        this.$set(this.refs, entid, [])
        this.loadEntRef(entid)
      })
    },
    cellStatus(field) {
      const cell = this.record[field.key]
      const initCell = this.initRecord[field.key]
      if (cell) {
        return (`${cell.value}` === `${initCell.value}` || (!cell.value && !initCell.value)) ? 'init' : 'changed'
      }
      return 'init'
    },
    selectClass(fld) {
      if (this.fldState(fld).state === null) {
        return null
      }
      if (this.fldState(fld).state === true) {
        return 'border rounded border-success'
      }
      return 'border rounded border-danger'
    },
    isCellEmpty(fld) {
      const cell = this.record[fld.key]
      return cell.value === null || cell.value === '' || (fld.datatype === 'ref' && cell.value === 0)
    },
    isCellChanged(fld) {
      return this.cellStatus(fld) === 'changed'
    },
    fldState(fld) {
      let stat = { state: null, status: '' }
      const isEmpty = this.isCellEmpty(fld)
      if (fld.isrequired) {
        if (isEmpty) {
          stat = { state: false, status: 'Attribute is required.' }
        } else if (this.isCellChanged(fld)) {
          stat = { state: true, status: '' }
        }
      }
      if (fld.isunique && !isEmpty) {
        if (!this.isCellChanged(fld)) {
          stat = { state: null, status: '' }
        } else if (this.record[fld.key].isUnique === true) {
          stat = { state: true, status: '' }
        } else if (this.record[fld.key].isUnique === false) {
          stat = { state: false, status: 'Value is not unique.' }
        } else {
          stat = { state: false, status: 'Unique check is required.' }
        }
      }
      return stat
    },
    getEntRef(fld) {
      if (!this.refs[fld.entityid]) {
        this.loadEntRef(fld.entityid)
      }
      return this.refs[fld.entityid]
    },
    loadEntRef(entityid) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'entref',
          param: { entityid },
        },
      })
        .then(response => {
          console.log('entref response', response)
          if (response.data.thread) this.delayEntref(entityid, response.data.thread)
          if (response.data.error) useCubus.toastError(this, response.data.error)
        })
        .catch(error => {
          console.log('entref error', error)
          useCubus.toastError(this, error)
        })
    },
    delayEntref(entityid, threadname) {
      setTimeout(() => this.loadThreadEntref(entityid, threadname), 500)
    },
    loadThreadEntref(entityid, threadname) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'thread',
          param: { threadname },
        },
      })
        .then(response => {
          if (response.data.thread) {
            if (response.data.thread.status === 'done') {
              console.log('loadThreadEntref', response)
              this.$set(this.refs, entityid, response.data.thread.result.ref)
            } else {
              this.delayEntref(entityid, threadname)
            }
          }
        })
        .catch(error => {
          console.log('loadThreadEntref error', error)
          useCubus.toastError(this, error)
        })
    },
    onInputVSelect(val, index) {
      if (!val) this.record[this.fields[index].key] = { value: 0, title: '' }
      this.onChange()
    },
    getRecord() { return this.record },
    onContext() {},
    isAdmin() {
      return useCubus.isAdmin()
    },
    getData() {
      // eslint-disable-next-line no-underscore-dangle
      return this._data
    },
    getProps() {
      // eslint-disable-next-line no-underscore-dangle
      return this._props
    },
    session() {
      return this.$store.getters['cubus-store/SESSION']
    },
    formatter(value) {
      return Number(value)
    },
    recordStatus() {
      if (this.fields.find(fld => this.cellStatus(fld) === 'changed')) {
        return 'changed'
      }
      /*
      for (const fld of this.fields) {
        if (this.cellStatus(fld) === 'changed') return 'changed'
      }
      */
      return 'init'
    },
    recordState() {
      if (this.fields.find(fld => this.fldState(fld).state === false)) return false
      /*
      for (const fld of this.fields) {
        if (this.fldState(fld).state === false) return false
      }
      */
      return true
    },
    field(key) {
      return this.fields.find(f => f.key === key)
    },
    isValid() {
      return this.recordStatus() !== 'init' && this.recordState()
    },
    onChange() {
      // console.log('change')
      this.$emit('change', this.isValid())
    },
    filterBy(option, label, search) {
      const pattern = `.*${search.replace(new RegExp(' ', 'gi'), '.*').replace(new RegExp('\\.\\*\\.\\*', 'gi'), ' ')}.*`
      const re = new RegExp(pattern, 'gi')
      return re.test(label || '')
    },
    fieldWidth(fld) {
      if (fld.rendertype === 'jsonfilter') { return 12 }
      if (fld.rendertype === 'jsonmodification') { return 12 }
      if (fld.datatype === 'json') { return 12 }
      return 6
    },
    checkUnique(fld, cell) {
      console.log('checkUnique', fld, cell)
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'checkunique',
          param: {
            objectid: this.objectFull.object.id,
            entityid: fld.entityid,
            value: cell.value,
          },
        },
      })
        .then(response => {
          console.log('check unique response', response)
          if (response.data) {
            if (response.data.thread) this.loadThreadCheckUnique(response.data.thread, cell)
            if (response.data.error) {
              useCubus.toastError(this, response.data.error)
            }
          }
        })
        .catch(error => {
          console.log('check unique error', error)
        })
    },
    filterFields() {
      if (this.objectFull.object.sid === 'mountstep') {
        if (this.mountOperRec) {
          if (this.mountOperRec.record.sid.value === 'insert') {
            if (this.filterSrcObj) {
              return this.filterSrcObj.attributes.map(a => useCubus.attributeToField(a))
            }
            return []
          }
          if (this.filterDestObj) {
            return this.filterDestObj.attributes.map(a => useCubus.attributeToField(a))
          }
          return []
        }
        return []
      }
      if (this.objectFull.object.sid === 'scalar') {
        if (this.filterSrcObj) {
          return this.filterSrcObj.attributes.map(a => useCubus.attributeToField(a))
        }
        return []
      }
      if (this.objectFull.object.sid === 'repexportquery') {
        if (this.filterObj) {
          return this.filterObj.attributes.map(a => useCubus.attributeToField(a))
        }
        return []
      }
      return this.fields.map(f => useCubus.attributeToField(f))
    },
    clearCell(fld) {
      this.record[fld.key] = { value: null, title: null }
    },
    isEmpty(fld, cell) {
      return cell.value === null || cell.value === '' || (fld.datatype === 'ref' && cell.value === 0)
    },
    filterParamFields() {
      if (this.objectFull.object.sid === 'mountstep') {
        if (this.filterParamObj) {
          return this.filterParamObj.attributes.map(a => useCubus.attributeToField(a))
        }
        return []
      }
      return this.fields.map(f => useCubus.attributeToField(f))
    },
    destinationFields() {
      if (this.filterDestObj) {
        return this.filterDestObj.attributes.map(a => useCubus.attributeToField(a))
      }
      return []
    },
    canDownload() {
      return this.objectFull.object && this.objectFull.object.access
          && (this.objectFull.object.access.download === 'grant' || this.objectFull.object.access.download === 'withgrant')
    },
    filterObjReady(obj) {
      this.filterObj = obj
    },
    filterSrcObjReady(obj) {
      this.filterSrcObj = obj
    },
    filterDestObjReady(obj) {
      this.filterDestObj = obj
    },
    filterParamObjReady(obj) {
      this.filterParamObj = obj
    },
    loadMountOperObj(obj) {
      console.log('loadMountOperObj', obj)
      this.mountOperObj = obj
    },
    loadMountOperRec(rec) {
      this.mountOperRec = rec
    },
  },
}
</script>

<style scoped>

</style>
