<template>
  <div>
    <b-row>
      <b-col cols="12">
        <label>Operand:</label>
        <v-select v-model="value.oper"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="opers"
                  :filter-by="filterBy"
                  @option:selected="onSelectOper"
        />
      </b-col>
    </b-row>
    <div v-if="value.oper" class="mt-1">
      <label>Argument(s):</label>
      <div class="bordered rounded p-1 border-secondary">
        <cbs-function-argument v-for="(arg, idx) in value.args" :key="idx" :value="arg" :types="argTypes" :index="idx" @delete="deleteArg" />
        <b-button size="sm" variant="success" class="mt-1" @click="addArgument">
          <feather-icon icon="PlusIcon"></feather-icon> Add argument
        </b-button>
      </div>
    </div>
    <cbs-data-object hide objectsid="functionoperand" @load="onLoadOperObj" />
    <cbs-data-query v-if="operObj" hide :object ="operObj" :orderby="operSort" @load="onLoadQuery" />
    <cbs-debug :context="this" />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsDataQuery from '@/cubus/components/query/CbsDataQuery.vue'
import CbsDataObject from '@/cubus/components/query/CbsDataObject.vue'
import {
  BButton, BCol, BRow,
} from 'bootstrap-vue'
import store from '@/store'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import CbsFunctionArgument from '@/cubus/components/funciton/CbsFunctionArgument.vue'

export default {
  name: 'CbsFunction',
  components: {
    CbsFunctionArgument,
    CbsDataObject,
    CbsDataQuery,
    CbsDebug,
    vSelect,
    BRow,
    BCol,
    BButton,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      operObj: null,
      operQuery: null,
      opers: [],
      argTypes: [],
      operSort: {
        isActive: true,
        sorts: [
          {
            isActive: true,
            name: 'name',
            oper: { sid: 'asc' },
            entity: { sid: 'name' },
          },
        ],
      },
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.initTypes()
    },
    onLoadOperObj(obj) {
      this.operObj = obj
    },
    onLoadQuery(query) {
      this.operQuery = query
      this.opers = this.getOpers()
    },
    filterBy(option, label, search) {
      const pattern = `.*${search.replace(new RegExp(' ', 'gi'), '.*').replace(new RegExp('\\.\\*\\.\\*', 'gi'), ' ')}.*`
      const re = new RegExp(pattern, 'gi')
      return re.test(label || '')
    },
    getOpers() {
      if (this.operQuery) {
        return this.operQuery.recordset.records.map(r => ({
          value: r.id.value,
          sid: r.sid.value,
          title: r.name.value,
          args: r.argqty.value,
        }))
      }
      return []
    },
    initTypes() {
      if (store.getters['cubus-store/ARGUMENT_TYPES']) {
        this.types = store.getters['cubus-store/ARGUMENT_TYPES']
      } else this.loadTypes()
    },
    loadTypes() {
      useJwt.query({
        query: {
          method: 'argumenttypes',
          param: {},
        },
      })
        .then(response => {
          console.log('response', response)
          if (response.data.thread) {
            this.threadTypes(response.data.thread)
          } else if (response.data.error) {
            useCubus.toastError(this, response.dataerror)
          } else useCubus.toastError(this, 'Thread not provided.')
        })
        .catch(error => {
          console.log('error', error)
          useCubus.toastError(this.error)
        })
    },
    delayTypes(thread) { setTimeout(() => this.threadTypes(thread), 1000) },
    threadTypes(thread) {
      useJwt.query({
        query: {
          method: 'thread',
          param: { threadname: thread },
        },
      })
        .then(response => {
          console.log('response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread.status === 'done') {
            this.argTypes = response.data.thread.result.types
            store.commit('cubus-store/SET_ARGUMENT_TYPES', this.types)
          } else if (response.data.thread.error) {
            useCubus.toastError(this, response.data.thread.error)
          } else {
            this.delayTypes(thread)
          }
        })
        .catch(error => {
          console.log('error', error)
          useCubus.toastError(this, error)
        })
    },
    addArgument() {
      this.value.args.push(this.getNewArgument())
    },
    getNewArgument() {
      return {
        type: null,
        value: null,
      }
    },
    deleteArg(index) {
      this.value.args.splice(index, 1)
    },
    onSelectOper(oper) {
      console.log('onSelectOper', oper)
      if (oper.args > 0) {
        this.value.args = []
        for (let i = 0; i < oper.args; i++) {
          this.addArgument()
        }
      } else {
        this.value.args = []
      }
    },
  },
}
</script>

<style scoped>

</style>
